<template>
  <TourTooltip
    :has-next-step="hasNextStep"
    :has-previous-step="hasPreviousStep"
    :steps="steps"
    @next="nextStep($event)"
    @previous="previousStep($event)"
    @skip="$emit('skip')"
  />
</template>

<script>
import TourTooltip from '@/components/TourTooltip'

export default {
  name: 'TourExam',

  components: {
    TourTooltip,
  },

  data() {
    return {
      steps: [
        {
          target: '#v-step-4',
          content: `
            <h4 class="v-step__title">Esta é a sua primeira prova digital</h4>
            <span class="v-step__body">
              Vamos te ajudar com 3 dicas para que você entenda o produto e faça a prova com tranquilidade.
            </span>`,
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '#v-step-5',
          content: `
            <h4 class="v-step__title">Pausar e Finalizar a Prova</h4>
            <span class="v-step__body">
              Aqui estão as opções de pausar e finalizar a prova. Vamos explicar cada uma delas.
            </span>`,
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '#v-step-6',
          content: `
            <h4 class="v-step__title">Pausar prova e sair</h4>
            <span class="v-step__body">
              Caso precise pausar a prova, seu progresso será salvo e você poderá retomá-la de onde parou.
            </span>`,
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '#v-step-7',
          content: `
            <h4 class="v-step__title">Finalizar prova</h4>
            <span class="v-step__body">
              Clique para revisar as respostas e  entregar a prova. Após finalizar, você não acessa mais a prova.
            </span>`,
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '#v-step-4',
          content: `
            <h4 class="v-step__title">Agora é com você! Boa prova.</h4>
            <span class="v-step__body">
              Agora que você aprendeu como fazer uma prova por aqui, pode se concentrar em dar o seu melhor. 
            </span>`,
          params: {
            placement: 'bottom',
          },
        },
      ],
      hasPreviousStep: false,
      hasNextStep: true,
    }
  },

  methods: {
    nextStep(tour) {
      const step = tour.currentStep
      const totalSteps = 3
      this.hasPreviousStep = tour.currentStep + 1 !== 0
      this.hasNextStep = step + 1 < tour.steps.length - 1
      if (tour.isLast) {
        tour.stop()
      } else {
        const toogleButton = document.querySelector('.popover__toggle')
        if (tour.isFirst && toogleButton) {
          toogleButton.click()
        }

        if (step < totalSteps) {
          this.$emit('next', true)
        } else {
          this.$emit('next', false)
        }
        tour.nextStep()
      }
    },

    previousStep(tour) {
      const step = tour.currentStep
      const firstStep = 1
      this.hasPreviousStep = tour.currentStep - 1 !== 0
      this.hasNextStep = step - 1 < tour.steps.length - 1
      const toogleButton = document.querySelector('.popover__toggle')
      if (tour.isLast && toogleButton) {
        toogleButton.click()
      }

      if (step > firstStep) {
        this.$emit('previous', true)
      } else {
        this.$emit('previous', false)
      }

      tour.previousStep()
    },
  },
}
</script>
